<template>
  <div class="vx-col md:w-1/1 w-full">
    <vx-card title="Companies">
      <div v-bind:class="[detail ? detailShow : '', detailHide]">
        <vs-table
          search
          stripe
          border
          description
          :sst="true"
          :data="table.data"
          :max-items="table.length"
          :total="table.total"
          @search="handleSearch"
          @change-page="handleChangePage"
          @sort="handleSort"
        >
          <template slot="header">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2">{{this.table.start}} - {{this.table.end}} of {{this.table.total}}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item
                  v-for="item in table.limits"
                  :key="item"
                  @click="handleChangelength(item)"
                >
                  <span>{{item}}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </template>
          <template slot="thead">
            <vs-th sort-key="name">Name</vs-th>
            <vs-th sort-key="country">Country</vs-th>
            <vs-th sort-key="province">Province</vs-th>
            <vs-th sort-key="city">City</vs-th>
            <vs-th sort-key="district">District</vs-th>
            <vs-th sort-key="sub_district">Sub District</vs-th>
            <vs-th sort-key="address">Address</vs-th>
            <vs-th>Action</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].name">{{ data[indextr].name }}</vs-td>
              <vs-td :data="data[indextr].country">{{ data[indextr].country }}</vs-td>
              <vs-td :data="data[indextr].province">{{ data[indextr].province }}</vs-td>
              <vs-td :data="data[indextr].city">{{ data[indextr].city }}</vs-td>
              <vs-td :data="data[indextr].district">{{ data[indextr].district }}</vs-td>
              <vs-td :data="data[indextr].sub_district">{{ data[indextr].sub_district }}</vs-td>
              <vs-td :data="data[indextr].address">{{ data[indextr].address }}</vs-td>
              <vs-td>
                <vs-button
                  radius
                  v-on:click="clickTr(data[indextr].id)"
                  color="primary"
                  type="border"
                  icon-pack="feather"
                  icon="icon-edit"
                ></vs-button>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination
          style="padding-top:5px"
          :total="table.totalPage"
          v-model="setPage"
        />
      </div>
    </vx-card>
  </div>
</template>

<script>
  export default {
    data(){
      return {
        detailShow: "core vx-col md:w-1/2 w-full mb-base ",
        detailHide: "core vx-col md:w-1/1 w-full mb-base ",
        detail: false,
        deleteId: null,
        customerId: null,
        table: {
          data: [],
          length: 10,
          page: 1,
          search: "",
          order: "id",
          sort: "desc",
          total: 0,
          totalPage: 0,
          totalSearch: 0,
          limits: [10, 25, 50, 100, "All"],
          start: 1,
          end: 0
        }
      };
    },
    methods: {
      handleSearch(searching) {
        this.table.search = searching;
        this.table.page = 1;
        this.getData();
      },
      handleChangePage(page) {
        this.table.page = page;
        this.getData();
      },
      handleSort(key, active) {
        this.table.order = key;
        this.table.sort = active;
        this.getData();
      },
      handleChangelength(val) {
        this.table.length = val == "All" ? this.table.total : val;
        this.table.page = 1;
        this.getData();
      },
      getData() {
        this.$vs.loading();
        this.$http
          .get("/api/v1/setting/company", {
            params: {
              length: this.table.length,
              page: this.table.page,
              search: this.table.search,
              order: this.table.order,
              sort: this.table.sort
            }
          })
          .then(resp => {
            if (resp.status == "success") {
              this.table.total = resp.data.total_record;
              this.table.totalPage = resp.data.total_page;
              this.table.totalSearch = resp.data.total_record_search;
              this.table.length = resp.data.total_record_per_page;
              this.table.data = resp.data.records;
              this.setStartEnd();
              this.$vs.loading.close();
            } else {
              console.log(resp.data);
            }
          });
      },
      setStartEnd() {
        let valStart = this.table.length * this.table.page - this.table.length + 1;
        if (valStart > this.table.total) {
          valStart = 1;
        }
        if (this.table.total == 0) {
          valStart = 0;
        }
        let valEnd = this.table.length * this.table.page;
        if (valEnd > this.table.total) {
          valEnd = this.table.total;
        }
        if (this.table.totalSearch < this.table.total) {
          valEnd = this.table.totalSearch;
        }

        this.table.start = valStart;
        this.table.end = valEnd;
      },
      // handleCustomerAddress(id) {
      //   this.$router.push("/master/customer-address/" + id);
      // }
      clickTr(val) {
        // var x = location.href;
        // location.href = x+'/edit/'+val;
        this.$router.push("/setting/company/edit/" + val);
      },
    },
    computed: {
      setPage: {
        get() {
          return 1;
        },
        set(val) {
          this.handleChangePage(val);
        }
      }
    }
  }
  //   methods: {
  //     reloadData() {
  //       this.$vs.loading();
  //       this.$http
  //       .get("api/v1/setting/company")
  //       .then(
  //         resp => {
  //           if (resp.code == 500) {
  //             this.$vs.loading.close();
  //           } else {
  //             // this.data = resp.data;
  //             this.responseData = resp.data;
  //             this.$vs.loading.close();
              
  //           }
  //         },
  //         // err => {
  //         //   this.$vs.loading.close();
  //         // }
  //       );
  //     },
  //     clickTr(val) {
  //       // this.closeDetail();
  //       // setTimeout(function() {}, 3000);
  //       // this.dataId = val.ID;
  //       // this.selectedData = val;
  //       // this.detail = true;
  //       var x = location.href;
  //       location.href = x+'/edit/'+val;
  //       // alert(x, val)
  //     },
  //   }
  // };
</script>